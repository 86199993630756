<template>
	<div class="modal-wrapper">
		<div class="modal-overlay"></div>

		<section class="feedback-modal modal card shadow rythm-v" role="dialog">
			<header class="flex-row flex-center-v flex-nowrap">
				<h2 class="flex-item--100 text-center">Merci, n’hésitez pas à poser une autre question&nbsp;!</h2>
			</header>
			<form @submit.prevent="handleSubmit">
				<div class="flex-row flex-center-h rythm-v">
					<div class="input-group">
						<label for="feedback" class="text-center">Souhaitez-vous laisser un message à l'expert&nbsp;?</label>
						<textarea name="feedback" id="feedback" cols="30" rows="4" v-model="feedback"></textarea>
					</div>

					<div class="input-group--controls flex-item--100">
						<button class="button--primary" type="submit">
							<svg
								class="icon icon-check"
								role="img"
								xmlns="http://www.w3.org/2000/svg"
								width="18"
								height="13"
								fill="none"
								viewBox="0 0 18 13"
							>
								<path
									fill="currentColor"
									fill-rule="evenodd"
									d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z"
									clip-rule="evenodd"
								/>
							</svg>
							<span class="caption">Terminer</span>
						</button>
					</div>
				</div>
			</form>
		</section>
	</div>
</template>

<style lang="scss" scoped>
@import "~@/scss/01-settings/settings.colors";
@import "~@/scss/02-tools/tools.mixins";
@import "~@/scss/06-components/components.modal";

header h2 {
	color: $color-blue;
}
</style>

<script>
export default {
	name: "FeedbackModal",

	data: function() {
		return {
			feedback: "",
		};
	},

	methods: {
		closeModal: function() {
			this.$emit("modalClose");
		},

		// submit event to other components
		handleSubmit: function(event) {
			this.$emit("submit", this.feedback);
		},
	},
};
</script>
