<template>
	<div class="user-card">
		<picture class="avatar">
			<img role="img" :src="user.avatar" :alt="`${user.firstname[0]}${user.lastname[0]}`" width="60" height="60" />
		</picture>
		<div>
			<header>{{ user.firstname }} {{ user.lastname }}</header>
			<footer>{{ user.specialty }}</footer>
		</div>
	</div>
</template>

<script>
export default {
	name: "UserAvatar",
	props: ["user"],
};
</script>
