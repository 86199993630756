<template>
	<div class="modal-wrapper">
		<div class="modal-overlay"></div>

		<section class="nps-modal modal card shadow" role="dialog">
			<header class="flex-row flex-align-spaced flex-center-v flex-nowrap">
				<h2 class="flex-item--100 text-center">Êtes-vous satisifait de la réponse de l’expert&nbsp;?</h2>
			</header>
			<form @submit.prevent="handleSubmit">
				<div class="wrapper flex-row flex-center-h rythm-v">
					<div class="stars-rating">
						<label
							class="item"
							:for="`level${star}`"
							v-for="star in stars"
							:key="`star-${star}`"
							:aria-label="`${star}/${stars}`"
						>
							<input type="radio" name="rating" :id="`level${star}`" :value="star" v-model="score" />
							<svg
								role="img"
								aria-hidden="true"
								class="icon-star"
								viewBox="0 0 32 31"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill="currentColor"
									class="star-stroke"
									d="m16 0c0.5536 0 1.0593 0.31866 1.3043 0.82201l4.1564 8.5377 9.2951 1.3775c0.5477 0.0812 1.0025 0.4705 1.1732 1.0044 0.1708
						0.5339 0.0278 1.1198-0.3687 1.5114l-6.7247 6.6409 1.5871 9.382c0.0936 0.5533-0.1308 1.1125-0.5787 1.4425-0.448 0.3299-1.0419 0.3734-1.5319
						0.1121l-8.3121-4.4321-8.312 4.4321c-0.49006 0.2613-1.0839
						0.2178-1.5319-0.1121-0.44797-0.33-0.67232-0.8892-0.57873-1.4425l1.587-9.382-6.7247-6.6409c-0.39653-0.3916-0.53948-0.9775-0.36875-1.5114 0.17073-0.5339
						0.62554-0.9232 1.1733-1.0044l9.2951-1.3775 4.1564-8.5377c0.2451-0.50335 0.7507-0.82201 1.3043-0.82201zm0 4.8067-3.1902 6.553c-0.2117 0.4349-0.6207
						0.7364-1.094 0.8065l-7.1363 1.0576 5.1626 5.0984c0.34313 0.3388 0.49973 0.8272 0.41873 1.3058l-1.2181 7.2008 6.3802-3.4019c0.4238-0.226 0.9302-0.226
						1.354 0l6.3802 3.4019-1.2181-7.2008c-0.081-0.4786 0.0756-0.967
						0.4187-1.3058l5.1627-5.0984-7.1363-1.0576c-0.4733-0.0701-0.8823-0.3716-1.094-0.8065l-3.1902-6.553z"
									clip-rule="evenodd"
								/>
								<path
									fill="currentColor"
									class="star-fill"
									d="m16 0c0.5536 0 1.0593 0.31866 1.3043 0.82201l4.1564 8.5377 9.2951 1.3775c0.5477 0.0812 1.0025 0.4705 1.1732 1.0044 0.1708
							0.5339 0.0278 1.1198-0.3687 1.5114l-6.7247 6.6409 1.5871 9.382c0.0936 0.5533-0.1308 1.1125-0.5787 1.4425-0.448 0.3299-1.0419 0.3734-1.5319
							0.1121l-8.3121-4.4321-8.312 4.4321c-0.49006 0.2613-1.0839
							0.2178-1.5319-0.1121-0.44797-0.33-0.67232-0.8892-0.57873-1.4425l1.587-9.382-6.7247-6.6409c-0.39653-0.3916-0.53948-0.9775-0.36875-1.5114 0.17073-0.5339
							0.62554-0.9232 1.1733-1.0044l9.2951-1.3775 4.1564-8.5377c0.2451-0.50335 0.7507-0.82201 1.3043-0.82201z"
									clip-rule="evenodd"
								/>
								<text
									style="font-size:.5em;font-weight:900;text-rendering:optimizelegibility;"
									x="50%"
									y="56%"
									dominant-baseline="middle"
									text-anchor="middle"
								>
									{{ star }}
								</text>
							</svg>
						</label>
					</div>
					<div class="legend flex-row flex-center-v flex-center-h flex-align-spaced">
						<span class="caption">Pas&nbsp;du&nbsp;tout</span>
						<span class="caption">Très&nbsp;satisfait</span>
					</div>

					<button class="button--secondary" type="submit" :disabled="score === null">
						<span class="caption">Continuer</span>
						<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
							<path
								fill="currentColor"
								fill-rule="evenodd"
								d="M8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893C6.90237 0.683418 6.90237 1.31658 7.29289 1.70711L12.5858 7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H12.5858L7.29289 14.2929C6.90237 14.6834 6.90237 15.3166 7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071L15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L8.70711 0.292893Z"
								clip-rule="evenodd"
							/>
						</svg>
					</button>
				</div>
			</form>
		</section>
	</div>
</template>

<style lang="scss" scoped>
@import "~@/scss/02-tools/tools.mixins";
@import "~@/scss/06-components/components.modal";
@import "~@/scss/06-components/components.stars-rating";

.nps-modal {
	width: 40rem;
	max-width: 100%;
}

.legend {
	font-size: 0.875rem;
	flex-basis: 100%;
}

@media all and (max-width: 768px) {
	.nps-modal.modal {
		top: 50%;
		transform: translateY(-50%) translateX(-50%);
		transform-origin: center;
		height: auto !important;
	}
}
</style>

<script>
export default {
	name: "NetPromoterScoreModal",

	data: function() {
		return {
			score: null,
			stars: 10,
		};
	},

	methods: {
		// submit event to other components
		handleSubmit: function(event) {
			this.$emit("submit", this.score);
		},
	},
};
</script>
