<template>
  <div class="modal-wrapper" open="open">
    <div class="modal-overlay"></div>

    <section class="ref-upload-modal modal card shadow rythm-v" role="dialog">
      <header class="flex-row flex-align-spaced flex-center-v flex-nowrap">
        <h2 class="flex-item--100 text-center">Références de la question</h2>
        <button
          class="button--icon"
          @click="closeModal"
          :disabled="closeButtonDisabled"
        >
          <svg
            role="img"
            class="icon"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="none"
            viewBox="0 0 14 14"
          >
            <title>Fermer</title>
            <path
              fill="currentColor"
              fill-rule="evenodd"
              d="M13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893Z"
              clip-rule="evenodd"
            />
            <path
              fill="currentColor"
              fill-rule="evenodd"
              d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </header>

      <p>
        <em
          >Assurez-vous que tous les fichiers soient envoyés avant d’envoyer
          votre réponse.</em
        >
      </p>

      <ul
        class="refs-list"
        v-if="question.references && question.references.length"
      >
        <li
          class="flex-row flex-align-spaced flex-center-v"
          v-for="ref in question.references"
          :key="`ref-${ref.id}`"
        >
          <a
            :href="`${API_URL}/file/${ref.hash}?jwt=${userToken}`"
            target="_blank"
            rel="noreferrer"
            >{{ ref.filename }}</a
          >
          <button
            class="button--icon button-delete"
            type="button"
            @click="confirmDeleteReference(ref.hash)"
          >
            <svg
              viewBox="0 0 16 18"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="18"
              fill="currentColor"
              class="icon"
            >
              <path
                fill-rule="evenodd"
                d="M4.96514 0.527208C5.29854 0.189642 5.75073 0 6.22222 0H9.77778C10.2493 0 10.7015 0.189642 11.0349 0.527208C11.3683 0.864773 11.5556 1.32261 11.5556 1.8V3.6H15.1111C15.602 3.6 16 4.00294 16 4.5C16 4.99706 15.602 5.4 15.1111 5.4H15.0499L14.3382 15.4917C14.2903 16.1728 13.9892 16.8104 13.4957 17.2758C13.0021 17.7413 12.3527 18 11.6782 18H4.3218C3.64732 18 2.99789 17.7413 2.50431 17.2758C2.01079 16.8104 1.70975 16.173 1.66181 15.4919L0.950097 5.4H0.888889C0.397969 5.4 0 4.99706 0 4.5C0 4.00294 0.397969 3.6 0.888889 3.6H4.44444V1.8C4.44444 1.32261 4.63175 0.864773 4.96514 0.527208ZM6.22222 3.6H9.77778V1.8H6.22222V3.6ZM2.7324 5.4L3.43508 15.3637C3.45104 15.5908 3.5514 15.8035 3.71593 15.9586C3.88045 16.1138 4.09693 16.2 4.32178 16.2H11.6782C11.9031 16.2 12.1195 16.1138 12.2841 15.9586C12.4486 15.8035 12.5489 15.591 12.5649 15.3639L13.2676 5.4H2.7324ZM6.22222 7.2C6.71314 7.2 7.11111 7.60294 7.11111 8.1V13.5C7.11111 13.9971 6.71314 14.4 6.22222 14.4C5.7313 14.4 5.33333 13.9971 5.33333 13.5V8.1C5.33333 7.60294 5.7313 7.2 6.22222 7.2ZM9.77778 7.2C10.2687 7.2 10.6667 7.60294 10.6667 8.1V13.5C10.6667 13.9971 10.2687 14.4 9.77778 14.4C9.28686 14.4 8.88889 13.9971 8.88889 13.5V8.1C8.88889 7.60294 9.28686 7.2 9.77778 7.2Z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="caption">Retirer</span>
          </button>
        </li>
      </ul>

      <file-pond
        name="documents"
        ref="pond"
        instant-upload="true"
        chunk-uploads="true"
        max-files="10"
        max-parallel-uploads="4"
        allow-multiple="true"
        allow-revert="true"
        allow-replace="false"
        ignore-files="['.DS_Store', 'thumbs.db', 'desktop.ini']"
        label-idle="Cliquer ou déposer vos fichiers ici pour les ajouter."
        label-file-loading="Préparation…"
        label-file-processing="Envoi du fichier…"
        label-file-processing-complete="Fichier envoyé"
        label-file-processing-aborted="Envoi annulé"
        label-file-remove-error="Erreur lors de la suppression"
        label-tap-to-retry="Cliquer pour recommencer"
        label-button-remove-item="Supprimer"
        label-button-abort-item-load="Abort Load"
        label-button-retry-item-load="Recommencer"
        label-button-abort-item-processing="Abort Process"
        label-button-undo-item-processing="Undo"
        label-button-retry-item-processing="Recommencer"
        label-button-process-item="Envoyer"
        :label-tap-to-cancel="labelTapToCancel"
        :label-tap-to-undo="labelTapToUndo"
        :label-file-processing-error="labelFileProcessingError"
        :files="documents"
        :server="pondOptions.server"
        @init="handleFilePondInit"
        @addfile="handleFilePond_addfile"
        @addfilestart="handleFilePond_addfilestart"
        @processfilestart="handleFilePond_processfilestart"
        @processfileprogress="handleFilePond_processfileprogress"
        @processfilerevert="handleFilePond_processfilerevert"
        @processfileundo="handleFilePond_processfileundo"
        @removefile="handleFilePond_removefile"
        @updatefiles="handleFilePond_updatefiles"
        @processfiles="handleFilePond_processfiles"
      />

      <div class="input-group--inline-controls">
        <button
          type="button"
          class="button--primary"
          @click="closeModal"
          :disabled="closeButtonDisabled"
        >
          <span class="caption">Fermer</span>
        </button>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/scss/02-tools/tools.mixins";
@import "~@/scss/06-components/components.modal";
@import "~@/scss/06-components/components.filepond";
</style>

<script>
// Axios
import { HTTP } from "../http-common.js";

// Import Vue FilePond
import vueFilePond, { setOptions } from "vue-filepond";

// Import FilePond plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilepondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileMetadata from "filepond-plugin-file-metadata";

// TUS chunk upload
import * as tus from "tus-js-client";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilepondPluginFileValidateSize,
  FilePondPluginFileMetadata
);

import { EventBus } from "../event-bus.js";

export default {
  name: "RefsUploadModal",

  components: {
    FilePond,
  },

  props: ["question", "user"],

  data: function() {
    return {
      API_URL: HTTP.defaults.baseURL,
      userToken: this.$store.state.userToken,
      documents: [],

      fileIsProcessing: false,
      closeButtonDisabled: false,

      labelFileProcessingError: "Erreur pendant le téléchargement",
      labelTapToUndo: "Cliquer pour supprimer",
      labelTapToCancel: "Cliquer pour annuler",

      pondOptions: {
        // Filepond option, replaced with custom process (tus)
        // but specified just in case…
        chunkUploads: true,

        server: {
          // Filepond still handles some requests (such as DELETE)
          url: `${HTTP.defaults.baseURL}/files`,
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${this.$store.state.userToken}`,
          },

          // Le processus d'upload est passé à tus-js-client qui gère le chunk upload
          process: (
            fieldName,
            file,
            metas,
            load,
            error,
            progress,
            abort,
            transfer,
            options
          ) => {
            var upload = new tus.Upload(file, {
              endpoint: `${HTTP.defaults.baseURL}/files`,
              chunkUploads: true,
              chunkSize: 5000000,
              retryDelays: [1000],
              chunkRetryDelays: [500],
              uploadDataDuringCreation: true,
              headers: {
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${this.$store.state.userToken}`,
              },
              withCredentials: false,

              // NOTE: the PATCH method may not be supported by the remote server.
              // use POST instead with an extra `X-HTTP-Method-Override: PATCH` header.
              // The ankitpokhrel/tus-php library will handle the alternative POST requests by itself.
              overridePatchMethod: true,

              // Remove file fingerprints from localStorage
              // NOTE: If not working, we disable resumable uploads below…
              removeFingerprintOnSuccess: true,
              // Disabling resumable uploads: can't pause and resume uploads
              // but allow to upload the same filename again
              resume: false,
              canStoreURLs: false,

              // Additionnal file data
              metadata: {
                filename: file.name,
                filetype: file.type,
                // this is a question attachment
                objectid: this.question.id,
                uploadtype: "references",
              },

              onError: (err) => {
                this.closeButtonDisabled = false;

                // We try to get custom error messages from the server response
                let responseText =
                  (upload && upload._xhr && upload._xhr.responseText) || null;
                let errorMsg = "Erreur pendant le téléchargement";
                if (responseText)
                  errorMsg = JSON.parse(responseText).payload.error;

                console.warn("onError", err, responseText, errorMsg);

                EventBus.$emit(
                  "trackEvent",
                  "Références Question",
                  `Erreur envoi pour question ${this.question.id} par userID ${this.user.id}`
                );

                if (errorMsg) this.labelFileProcessingError = errorMsg;
                return error(errorMsg);
              },

              onProgress: (bytesUploaded, bytesTotal) => {
                var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(
                  2
                );
                progress(true, bytesUploaded, bytesTotal);
                // console.log(bytesUploaded, bytesTotal, percentage + "%");
              },

              onSuccess: () => {
                console.info("uploaded", upload);
                load(upload.url.split("/").pop());
              },
            });

            // Start the upload
            upload.start();
          },
        },
      },
    };
  },

  methods: {
    /**
     * Toggle Close button disabled
     */
    toggleCloseButtonDisabled(state) {
      // Prevent if a file is processing
      if (this.fileIsProcessing && !state) return false;
      this.closeButtonDisabled = state;
    },

    confirmDeleteReference: function(hash) {
      this.$toasted.show(
        '<div class="message-wrapper"><strong>Retirer la référence de cette question&nbsp;?</strong></div>',
        {
          containerClass: "toasted",
          theme: "primary",
          position: "top-center",
          duration: null,
          closeOnSwipe: false,
          action: [
            {
              text: "Annuler",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
                return;
              },
            },
            {
              text: "Confirmer",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
                this.$emit("deleteReference", hash);
              },
            },
          ],
        }
      );
    },

    // Filepond intance
    handleFilePondInit: function() {
      console.log("FilePond has initialized");
      // FilePond instance methods are available on `this.$refs.pond`
    },

    // File has been loaded, if the detail object contains an error property, something went wrong
    handleFilePond_addfile: function(e) {
      console.log("FilePond handleFilePond_addfile", e);
    },
    // Started file load
    handleFilePond_addfilestart: function(e) {
      console.log("FilePond handleFilePond_onaddfilestart", e, e.status);
    },

    // Remove the file from queue (no delete)
    handleFilePond_removefile: function(e) {
      console.log("FilePond handleFilePond_removefile", e);
    },

    // Starting processing file
    handleFilePond_processfilestart: function(e) {
      // Too late to cancel!
      this.labelTapToCancel = "";
      console.log("FilePond handleFilePond_processfilestart", e);
    },

    // File progress
    handleFilePond_processfileprogress: function(e) {
      // console.log("FilePond handleFilePond_processfileprogress", e);
    },

    // Cancel?
    handleFilePond_processfilecancel: function(e) {
      console.log("FilePond handleFilePond_processfilecancel", e);
    },

    // Process file undo
    handleFilePond_processfileundo: function(e) {
      console.log("FilePond handleFilePond_processfileundo", e);
    },

    // Abort (before upload has started)
    // @processfileabort="handleFilePond_processfileabort"
    handleFilePond_processfileabort: function(e) {
      console.log("FilePond handleFilePond_processfileabort", e);
    },

    // Delete uploaded file
    handleFilePond_processfilerevert: function(e) {
      console.log("FilePond handleFilePond_processfilerevert", e.file.name);
      this.$emit("refreshQuestion");
      EventBus.$emit(
        "trackEvent",
        "Références Question",
        `Ref. ${e.file.name} supprimée pour question ${this.question.id} par userID ${this.user.id}`
      );
    },

    // A file has been added or removed
    handleFilePond_updatefiles: function(e) {
      console.log("FilePond handleFilePond_updatefiles", e);
    },

    // All files uploaded
    handleFilePond_processfiles: function(e) {
      console.log("FilePond handleFilePond_processfiles", e);
      this.$emit("refreshQuestion");
      EventBus.$emit(
        "trackEvent",
        "Références Question",
        `Références envoyées pour question ${this.question.id} par userID ${this.user.id}`
      );
    },

    closeModal: function() {
      this.$emit("refreshQuestion");
      this.$emit("modalClose");
    },
  },
};
</script>
